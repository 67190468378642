.btn_go{
    background-color:#A6153E ;
    color: white;
    margin: 0px 0px 0px 10px;
    width:auto;
}
.btn_go:hover{
    background-color:#000000 ;
    color: white;
    margin: 0px 0px 0px 10px;
    width: auto;
}
.btn_go:active{
    background-color:#A6153E !important;
    color: white;
    margin: 0px 0px 0px 10px;
    width: auto;
}


.marginPerso{
    --bs-gutter-x: 0rem !important;
    --bs-gutter-y: 0 !important;
}
.Margin{
   position: absolute;
   right: 50px;
   top:0px;
}
.title{
    font-size: 17px;

    font-weight: 500;
}
.containerP{
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
}
@media  screen and (max-width: 1200px){
    /*Tablets [601px -> 1200px]*/
    .containerP{
        width: 100%  !important;
        margin: 0px !important;
        padding: 0px !important;
    }
}
@media  screen and (max-width: 600px){
	/*Big smartphones [426px -> 600px]*/
    .containerP{
        width: 100%  !important;
        margin: 0px !important;
        padding: 0px !important;
    }
}
@media  screen and (max-width: 425px){
	/*Small smartphones [325px -> 425px]*/
    .containerP{
        width: 100%  !important;
        margin: 0px !important;
        padding: 0px !important;
    }
}

.logaut{
    display: none;
}
.logautBTn{
    background-color:#A6153E !important;
    color: white;
    margin: 0px 0px 0px 10px;
    width: auto;
    position: absolute;
    right: 1px;
    top: 0px;
}


.loaderPetit {

        margin: auto;
        border: 10px solid #EAF0F6;
        border-radius: 50%;
        border-top: 10px solid #A6153E;
        width: 100px;
        height: 100px;
        animation: spinner 1s linear infinite;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;

  }

 
  @keyframes spinner {
    0% { 
        transform: rotate(0deg);
        opacity: 1;
     }
     50% { 
        opacity: 0; 
      }
    100% { 
        transform: rotate(360deg);
        opacity: 1; 
      }
  }
  .loaderGrand {

        margin: auto;
        border: 10px solid #EAF0F6;
        border-radius: 50%;
        border-top: 10px solid #A6153E;
        width: 100px;
        height: 100px;
        animation: spinner 1s linear infinite;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
 
  }

 
  @keyframes spinner {
    0% { 
        transform: rotate(0deg);
        opacity: 1;
     }
     50% { 
        opacity: 0; 
      }
    100% { 
        transform: rotate(360deg);
        opacity: 1; 
      }
  }