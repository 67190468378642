.alertRed{
    color: white;
    background-color: red;
}
.loader {

    margin: auto;
    border: 10px solid #EAF0F6;
    border-radius: 50%;
    border-top: 10px solid #A6153E;
    width: 100px;
    height: 100px;
    animation: spinner 1s linear infinite;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

  }

  
  @keyframes spinner {
    0% { 
        transform: rotate(0deg);
        opacity: 1;
     }
     50% { 
        opacity: 0; 
      }
    100% { 
        transform: rotate(360deg);
        opacity: 1; 
      }
  }

  .btn_serc{
    background-color: transparent;
    border: transparent;
    padding: 3px;

  }

  .none{
    display: none;
  }
