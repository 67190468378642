.inputP{
    display: block !important;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px);
    /* padding: 0.375rem 0.75rem !important; */
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}
.label{
    text-align:left ;
    color: #495057;
    font-size: 14px;
}
.persoN{
 
    width: auto !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.content{
    text-align: center;
}
.btnNew{
    text-align: right!important;
}
.content{
    
    margin-left: auto;
    margin-right: auto;
    width: 400px; /* largeur obligatoire pour être centré */
}
.formulaire{
    margin-top: 20px;
}

.contentPrincipal{
    margin-left: auto;
    margin-right: auto;
    width: 100%; /* largeur obligatoire pour être centré */
}
.alert{
    display: none;
    background-color: #495057;
    color: white;
    width: 300;
    height: auto;

}
.loader {
    margin: auto;
    border: 10px solid #EAF0F6;
    border-radius: 50%;
    border-top: 10px solid #A6153E;
    width: 100px;
    height: 100px;
    animation: spinner 1s linear infinite;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  
  @keyframes spinner {
    0% { 
        transform: rotate(0deg);
        opacity: 1;
     }
    100% { 
        transform: rotate(960deg);
        opacity: 1; 
      }
  }